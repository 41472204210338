import React, { useState } from "react";
import "./Products.scss";
import { Col, Row } from "reactstrap";
import { FaArrowRight, FaArrowDownLong } from "react-icons/fa6";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import call_back from "../../../assets/images/products/call_back.png";

const Products = ({ product }) => {
  const { mainImage, thumbnails, name, details, description } = product;
  const [currentMainImage, setCurrentMainImage] = useState(mainImage);

  const handleThumbnailClick = (image) => {
    const mainImageElement = document.querySelector(
      `.main-image-${name.replace(/\s+/g, "-")}`
    );
    mainImageElement.classList.add("fade-out");

    setTimeout(() => {
      setCurrentMainImage(image);
      mainImageElement.classList.remove("fade-out");
    }, 500);
  };

  return (
    <section className="product my-5 position-relative">
      <Row>
        <Col lg={4}>
          <img
            src={currentMainImage}
            alt="Main Product"
            className={`img-fluid main-image main-image-${name.replace(
              /\s+/g,
              "-"
            )}`}
          />
          <div className="row mt-4">
            {thumbnails.map((thumb, index) => (
              <Col key={index} xs={3}>
                <img
                  src={thumb}
                  alt={`Thumbnail ${index + 1}`}
                  className="img-fluid thumbnail"
                  onClick={() => handleThumbnailClick(thumb)}
                />
              </Col>
            ))}
          </div>
          <ButtonPrimary
            content="Get Best Quote"
            className="mt-3 w-100"
            icon={<FaArrowRight />}
          />
        </Col>

        <Col lg={8}>
          <p className="product_name">{name}</p>
          <div className="d-flex align-items-center mt-3">
            <p className="fw-bold fs-5 text-muted">Product Details</p>
            <p className="brochure ms-4">
              <FaArrowDownLong className="me-3" />
              Product Brochure
            </p>
          </div>
          <Row className="mt-4">
            <Col lg={6} className="product-details">
              {details.map((detail, index) => (
                <p key={index}>{detail.label}</p>
              ))}
            </Col>
            <Col lg={6} className="product-details">
              {details.map((detail, index) => (
                <p key={index}>{detail.value}</p>
              ))}
            </Col>
          </Row>
          <p className="product_desc mt-5">{description}</p>
        </Col>
      </Row>
      <img
        src={call_back}
        alt="CallBack"
        className="call_back_icon img-fluid"
      />
    </section>
  );
};

export default Products;
