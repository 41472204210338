import { Col, Row } from "antd";
import React from "react";
import "./AboutCompany.scss";
import nature from "../../../assets/images/about-company/nature.svg";
import legal from "../../../assets/images/about-company/legal.svg";
import employees from "../../../assets/images/about-company/total_employee.svg";
import establishment from "../../../assets/images/about-company/establishment.svg";
import turnover from "../../../assets/images/about-company/turnover.svg";
import gst from "../../../assets/images/about-company/gst.svg";

const AboutCompany = () => {
  return (
    <div className="about-company">
      <Row className="p-5 m-0" gutter={[40, 8]}>
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img src={nature} className="img-fluid" alt="Nature_Of_Business" />
            <p className="title">Nature of Business</p>
            <p className="value">Exporter and Manufacturer</p>
          </div>
        </Col>
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img src={legal} className="img-fluid" alt="Nature_Of_Business" />
            <p className="title">Legal Status of Firm</p>
            <p className="value">Individual - Proprietor</p>
          </div>
        </Col>{" "}
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img
              src={employees}
              className="img-fluid"
              alt="Nature_Of_Business"
            />
            <p className="title">Total No. of Employees</p>
            <p className="value">Upto 10 People</p>
          </div>
        </Col>{" "}
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img
              src={establishment}
              className="img-fluid"
              alt="Nature_Of_Business"
            />
            <p className="title">Year of Establishment</p>
            <p className="value">2010</p>
          </div>
        </Col>{" "}
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img
              src={turnover}
              className="img-fluid"
              alt="Nature_Of_Business"
            />
            <p className="title">Annual Turnover</p>
            <p className="value">Rs. 50 Lakh - 1 Crore</p>
          </div>
        </Col>{" "}
        <Col lg={4} md={6} sm={12}>
          <div className="d-flex flex-column text-center align-items-center">
            <img src={gst} className="img-fluid" alt="Nature_Of_Business" />
            <p className="title">GST No.</p>
            <p className="value">07FCSPS5559H1ZO</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutCompany;
