import React from "react";
import HeroSection from "../components/UI/HeroSection/HeroSection";

const Landing = () => {
  return (
    <section>
      <HeroSection />
    </section>
  );
};

export default Landing;
