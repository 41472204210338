import React from "react";
import AboutCompany from "../../components/UI/AboutCompany/AboutCompany";
import { Col, Row } from "reactstrap";
import about_us from "../../assets/images/about_us.png";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <section className="about-us">
      <Row className="p-5 m-0">
        <Col lg={8}>
          <h1 className="heading">About</h1>
          <h1 className="heading fw-bolder">NEO INSTRUMENT COMPANY</h1>
          <p className="content mt-3">
            Neo Instruments Company is a leading high-tech enterprise
            specializing in the research and development of XRF technology. We
            collaborate closely with Unique Metrical Technology Co., which has
            received prestigious awards for its contributions to the field,
            including the Third Prize of National Science & Technology
            Advancement and the second Prize for Science and Technology Progress
            at the departmental level in the building material industry. Our
            team of world-class technical experts has developed the
            X-fluorescence spectrometer, a globally recognized technological
            leader. This spectrometer is widely utilized for identifying,
            testing, and analyzing the components of various elements, making it
            particularly suitable for "RoHS" compliance testing, noble metal
            analysis (such as Gold and Silver), and other elemental testing
            applications.
          </p>
          <p className="content mt-3">
            Our Ux energy dispersive X fluorescence spectrometers are the result
            of years of experience and lessons learned from energy dispersive X
            fluorescence spectrometer technology. These high-tech products boast
            their own intellectual property rights and have achieved
            international recognition for their technical performance, meeting
            and even surpassing the standards set by similar products on the
            global market.
          </p>
        </Col>
        <Col
          lg={4}
          className="mt-3 mt-lg-0 d-flex justify-content-lg-end justify-content-center"
        >
          <img src={about_us} alt="About_Us" className="img-fluid" />
        </Col>
      </Row>
      <AboutCompany />
    </section>
  );
};

export default AboutUs;
