import React from "react";
import "./Footer.scss";
import { Col, Row } from "reactstrap";
import logo_black from "../../../assets/images/logo_black.png";

import { Link } from "react-router-dom";
import { CiLocationOn, CiMail, CiMobile3 } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="">
      <Row className="m-0 footer">
        <Col lg="2" md="6" sm="12">
          <img src={logo_black} alt="Company_Logo" className="img-fluid" />
        </Col>
        <Col lg="6" md="6" sm="12">
          <div className="contact_us mt-4">
            <div className="row m-0 mt-2">
              <div className="col-lg-3 col-3">
                <span className="sub_heading ">
                  <CiLocationOn className="me-2" /> Office :
                </span>
                {/* <img src={call_yellow} alt="Call_Icon" /> */}
              </div>
              <div className="col-lg-9 col-9">
                <span className="content ">
                  Shop No-7078, Rameshwari, Gali No-9, Nehru Nagar, Karol Bagh,
                  Central Delhi, Delhi - 110005, (India)
                </span>
              </div>
            </div>
            <div className="row m-0 mt-2">
              <div className="col-lg-3 col-3">
                <span className="sub_heading ">
                  <CiMobile3 className="me-2" /> Mobile:
                </span>
                {/* <img src={call_yellow} alt="Call_Icon" /> */}
              </div>
              <div className="col-lg-9 col-9">
                <span className="content ">+(91)-9911385644, 9899724004</span>
              </div>
            </div>
            <div className="row m-0 mt-2">
              <div className="col-lg-3 col-3">
                <span className="sub_heading ">
                  <IoCallOutline className="me-2" /> Phone:
                </span>
                {/* <img src={call_yellow} alt="Call_Icon" /> */}
              </div>
              <div className="col-lg-9 col-9">
                <span className="content ">+(91)-(11)-65366540, 28722051</span>
              </div>
            </div>
            <div className="row m-0 mt-2">
              <div className="col-lg-3 col-3">
                <span className="sub_heading ">
                  <CiMail className="me-2" /> Email:
                </span>
                {/* <img src={call_yellow} alt="Call_Icon" /> */}
              </div>
              <div className="col-lg-9 col-9">
                <span className="content ">
                  sales@neoxrf.com , info@neoxrf.com
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="m-0 ">
        <Col lg="12" className="p-0">
          <div className="copyright">
            Copyright © {currentYear} Neo Instrument Company All Rights Reserved
            || Designed by{" "}
            <Link
              className="text-decoration-none text-white"
              to="https://ocpl.tech"
              target="_blank"
            >
              OCPL Tech
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Footer;

// border-radius: 20px;
