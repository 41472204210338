import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Landing from "./pages/Landing";
import Navbar from "./components/layout/Sidebar/Sidebar";
import Footer from "./components/layout/Footer/Footer";
import AboutUs from "./pages/AboutUs/AboutUs";
import Sidebar from "./components/layout/Sidebar/Sidebar";
import CustomNavbar from "./components/layout/Navbar/Navbar";
import WhyUs from "./pages/WhyUs/WhyUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import GoldIndustry from "./pages/GoldMachine/GoldIndustry";

function App() {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  return (
    <>
      {isLandingPage ? null : <CustomNavbar />}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/gold-industry" element={<GoldIndustry />} />
      </Routes>
      {isLandingPage ? null : <Footer />}
    </>
  );
}

export default App;
