import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import home1 from "../../assets/images/gold_home1.png";
import home2 from "../../assets/images/gold_home2.png";
import home3 from "../../assets/images/gold_home3.png";
import home4 from "../../assets/images/gold_home4.png";
import homeFull from "../../assets/images/gold_home_full.png";
import "./GoldIndustry.scss";
import AboutCompany from "../../components/UI/AboutCompany/AboutCompany";
import ButtonSecondary from "../../components/common/ButtonSecondary/ButtonSecondary";
import Products from "../../components/common/Products/Products";
import product1 from "../../assets/images/products/product1.png";
import product2 from "../../assets/images/products/product2.png";
import product3 from "../../assets/images/products/product3.png";
import product4 from "../../assets/images/products/product4.png";

const productsData = [
  {
    mainImage: product1,
    thumbnails: [product1, product2, product3, product4],
    name: "Gold Purity Testing Machine",
    details: [
      { label: "Minimum Order Quantity", value: "1 Piece" },
      { label: "Machine Required", value: "Gold Testing Machine" },
      { label: "Voltage", value: "100-240 Voltz" },
      { label: "Frequency", value: "47Hz to 63Hz" },
      { label: "Speed", value: "Result in 30 seconds" },
      { label: "I Deal In", value: "New and Second Hand" },
      { label: "Weight", value: "25 kgs" },
      { label: "PC or Laptop Interface", value: "Comes with a PC" },
      { label: "Country of Origin", value: "Made in India" },
    ],
    description:
      "The two most distinctive features of the Galaxy Gold Series are precision video imaging, and “bottom-up” measurement using a motorized Z-axis with laser-based auto-focus. An available manual XY stage with 1.5X1.5” travel facilitates easy positioning of small and large parts.",
  },
  {
    mainImage: product2,
    thumbnails: [product1, product2, product3, product4],
    name: "Gold Jewellery Hallmarking Machine",
    details: [
      { label: "Minimum Order Quantity", value: "1 Piece" },
      { label: "Machine Required", value: "Hallmarking Machine" },
      { label: "Voltage", value: "100-240 Voltz" },
      { label: "Frequency", value: "47Hz to 63Hz" },
      { label: "Speed", value: "Result in 30 seconds" },
      { label: "I Deal In", value: "New and Second Hand" },
      { label: "Weight", value: "30 kgs" },
      { label: "PC or Laptop Interface", value: "Comes with a PC" },
      { label: "Country of Origin", value: "Made in India" },
    ],
    description:
      "The hallmarking machine offers precision in marking gold jewelry. It uses advanced technology to ensure accuracy and reliability in the hallmarking process.",
  },
];

const GoldIndustry = () => {
  const [showFullImage, setShowFullImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFullImage(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="gold-industry ">
      <div className={`image_animate ${showFullImage ? "show-full" : ""}`}>
        {!showFullImage ? (
          <Row className="m-0 p-0">
            <Col lg="3" className="d-flex justify-content-center m-0 p-0">
              <img className="img-fluid" src={home1} alt="Gold_Industry" />
            </Col>
            <Col lg="3" className="d-flex justify-content-center m-0 p-0">
              <img className="img-fluid" src={home2} alt="Gold_Industry" />
            </Col>
            <Col lg="3" className="d-flex justify-content-center m-0 p-0">
              <img className="img-fluid" src={home3} alt="Gold_Industry" />
            </Col>
            <Col lg="3" className="d-flex justify-content-center m-0 p-0">
              <img className="img-fluid" src={home4} alt="Gold_Industry" />
            </Col>
          </Row>
        ) : (
          <Col lg="12">
            <img className="img-fluid" src={homeFull} alt="Gold_Industry" />
          </Col>
        )}
        <div className="content position-absolute">
          <h1 className="heading">
            Measure the metal,
            <br /> master the market
          </h1>
          <p className="sub-head">Elite gold testing technology</p>
          <p className="description">
            Interested in what we offer?
            <br /> Reach out with any questions or business inquiries.
          </p>
          <ButtonSecondary
            content="Contact Us"
            className="mt-4 py-3 px-5 fs-4"
          />
        </div>
      </div>
      <AboutCompany />
      <Container>
        <div className="product-list d-flex justify-content-center flex-column align-items-center">
          <div className="title">Gold Industry</div>
          <p className="about mt-5">
            As a prominent and leading manufacturer based in New Delhi, we
            provide a range of products including the gold purity testing
            machine, gold jewellery hallmarking machine, combined gold testing
            and hallmarking machine, gold testing & karat analyzer machine, gold
            testing machine, and the Neo Alfa Elite Super Spectrum.
          </p>
          {productsData.map((product, index) => (
            <Products key={index} product={product} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default GoldIndustry;
