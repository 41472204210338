import React from "react";
import { Modal, Button } from "antd"; // Assuming you are using Ant Design
import "./CallModal.scss";
import vector1 from "../../../assets/images/vector1.png";
import vector2 from "../../../assets/images/vector2.png";
import logo_black from "../../../assets/images/logo_black.png";
import { Form, FormGroup, Input, Label } from "reactstrap";

const CallModal = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        centered
        onOk={handleOk}
        className="position-relative"
        onCancel={handleCancel}
      >
        <img src={vector1} alt="Vector1" className="vector1 img-fluid" />
        <img src={vector2} alt="Vector2" className="vector2 img-fluid" />
        <img src={logo_black} alt="Logo Black" className="logo" />
        <p className="call_back">
          Connect with <b> Neo Instruments Company </b>on your mobile device
          now.
        </p>
        <Form>
          <FormGroup floating className="mt-4">
            <Input id="name" name="name" placeholder="Name" type="text" />
            <Label for="name">Enter Your Mobile Number</Label>
          </FormGroup>{" "}
        </Form>
      </Modal>
    </>
  );
};

export default CallModal;
