import React, { useState } from "react";
import "./Navbar.scss";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "../../common/ButtonSecondary/ButtonSecondary";
import { LuPhoneCall } from "react-icons/lu";
import logo from "../../../assets/images/logo.png";
import CallModal from "../../common/CallModal/CallModal";

const CustomNavbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Navbar expand="lg" className="custom-navbar">
        <NavbarBrand href="/" className="me-0 p-0">
          <img src={logo} alt="Company_Logo" className="custom-width" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto ms-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us">About Us</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Products
              </DropdownToggle>
              <DropdownMenu center="true">
                <DropdownItem>Gold Industry</DropdownItem>
                <DropdownItem>Laser Machines</DropdownItem>{" "}
                <DropdownItem>XRF Scientific Products</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/contact-us">Contact Us</NavLink>
            </NavItem>
          </Nav>
          <a
            className="text-white text-decoration-none"
            onClick={(e) => {
              e.preventDefault();
              showModal();
            }}
          >
            <ButtonSecondary
              content="Call Us"
              className="px-4 py-3"
              icon={<LuPhoneCall />}
            />
          </a>
          <CallModal
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          {/* <Button
            color="dark"
            className="d-flex justify-content-center align-items-center callUs"
          >
            <a
              href="tel://8541893616"
              className="text-white text-decoration-none"
            >
              <img
                src={call_white}
                alt="Call_Icon_White"
                className="me-2 call-icon"
              />
              Call Us
            </a>
          </Button> */}
        </Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
