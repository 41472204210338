import React from "react";
import "./HeroSection.scss";
import ButtonSecondary from "../../common/ButtonSecondary/ButtonSecondary";
import { FaArrowRight } from "react-icons/fa6";
import { Row, Col } from "reactstrap";
import gold_machine from "../../../assets/images/gold_machine.png";
import laser_machine from "../../../assets/images/laser_machine.png";
import xrf_product from "../../../assets/images/xrf_scientific.png";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../layout/Sidebar/Sidebar";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Sidebar />
      <video loop autoPlay muted>
        <source
          src="https://res.cloudinary.com/dgdl2xkcl/video/upload/v1715579319/neo-instruments/lytk48efkyaye4bor3mr.mp4"
          type="video/mp4"
        />
      </video>
      <div className="hero-section">
        <div className="content">
          <p className="tagline d-none d-md-block">
            Empowering Tomorrow's
            <br /> Technology Today
          </p>
          <Row className="m-0 d-flex ">
            <Col xs={12} md={3} lg={3} className="mt-lg-0">
              <div className="category_card">
                <div>
                  <img
                    src={gold_machine}
                    className="img-fluid"
                    alt="Gold_Machine"
                  />
                </div>

                <ButtonSecondary
                  className="mt-4 py-2 py-lg-3 px-1"
                  content="Gold Industry"
                  icon={<FaArrowRight />}
                  onClick={() => {
                    navigate("/gold-industry");
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="mt-3 mt-lg-0">
              <div className="category_card">
                <div>
                  <img
                    src={laser_machine}
                    className="img-fluid"
                    alt="Laser_Machine"
                  />
                </div>
                <ButtonSecondary
                  className="mt-4 py-2 py-lg-3 px-1"
                  content="Laser Machines"
                  icon={<FaArrowRight />}
                />
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} className="mt-3 mt-lg-0">
              <div className="category_card">
                <div>
                  <img
                    src={xrf_product}
                    className="img-fluid"
                    alt="Xrf_Product"
                  />
                </div>
                <ButtonSecondary
                  className="mt-4 py-2 py-lg-3 px-1"
                  content="XRF Scientific Products"
                  icon={<FaArrowRight />}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
