import React from "react";
import "./WhyUs.scss";
import { Col, Container, Row } from "reactstrap";
import whyUs1 from "../../assets/images/why_us/whyus1.png";
import whyUs2 from "../../assets/images/why_us/whyus2.png";
import whyUs3 from "../../assets/images/why_us/whyus3.png";
import whyUs4 from "../../assets/images/why_us/whyus4.png";
import AboutCompany from "../../components/UI/AboutCompany/AboutCompany";

const WhyUs = () => {
  return (
    <section className="why-us p-5">
      <Container>
        <h1 className="heading">WHY US?</h1>
        <p className="content">
          Our commitment to meeting customer requirements with precision has
          earned us a strong reputation in the market. Our extensive range of
          quality products offers customers a wide selection to choose from,
          ensuring they find the perfect fit for their needs. Our products are
          known for their durability, providing customers with long-lasting
          satisfaction.
        </p>
        <p className="content">
          We take pride in our timely delivery of products, instilling
          confidence in our customers and ensuring future orders. Additionally,
          our reputation is built on:
        </p>
        <div className="image_animate my-5">
          <Row>
            <Col lg="3">
              <img
                className="img-fluid"
                src={whyUs1}
                alt="A_smooth_and_reliable_vendor_base"
              />
            </Col>
            <Col lg="3">
              <img
                className="img-fluid"
                src={whyUs2}
                alt="Quality checks at every stage of production"
              />
            </Col>
            <Col lg="3">
              <img
                className="img-fluid"
                src={whyUs3}
                alt="A team of highly skilled experts"
              />
            </Col>
            <Col lg="3">
              <img
                className="img-fluid"
                src={whyUs4}
                alt="A warehouse equipped with modern amenities"
              />
            </Col>
          </Row>
        </div>
      </Container>
      <AboutCompany />
    </section>
  );
};

export default WhyUs;
