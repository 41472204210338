import React from "react";
import "./ContactUs.scss";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import ButtonPrimary from "../../components/common/ButtonPrimary/ButtonPrimary";
import { FaArrowRight } from "react-icons/fa6";

const ContactUs = () => {
  return (
    <section className="contact-us p-5 ">
      <Container className="d-flex flex-column  align-items-center">
        <h1 className="heading">
          Looking for a <span className="fw-bold">Quote?</span>
        </h1>
        <div className="contact-form mt-4 ">
          <Form>
            <FormGroup floating className="mt-5">
              <Input
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Enter Your Mobile Number"
                type="tel"
              />
              <Label for="mobileNumber">Mobile Number</Label>
            </FormGroup>{" "}
            <FormGroup floating className="mt-5">
              <Input id="name" name="name" placeholder="Name" type="text" />
              <Label for="name">Name</Label>
            </FormGroup>{" "}
            <FormGroup floating className="mt-5">
              <Input id="email" name="email" placeholder="Email" type="email" />
              <Label for="Email">Email</Label>
            </FormGroup>{" "}
            <ButtonPrimary
              className="mt-5 w-100"
              content="Send Inquiry"
              icon={<FaArrowRight />}
            />
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
