import React, { useState } from "react";
import "./Sidebar.scss";
import { Navbar, NavbarToggler, NavbarBrand } from "reactstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { IoArrowBackOutline } from "react-icons/io5";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar className="justify-content-end sidebar">
        {" "}
        {/* Add 'justify-content-end' class to align navbar to the right */}
        <NavbarBrand href="/" className="me-0 p-0">
          <img
            src={logo}
            alt="Company_Logo"
            className="custom-width img-fluid"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <div className={`menu-overlay ${isOpen ? "open" : ""} `}>
          <IoArrowBackOutline
            size="35"
            onClick={toggle}
            className="cursor-pointer"
          />
          <div className="d-flex mt-5 justify-content-center align-items-start flex-column">
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About Us
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/testimonials");
              }}
            >
              Testimonials
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/why-us");
              }}
            >
              Why us
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Our Journey
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </p>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Sidebar;
